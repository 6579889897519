import React, { useState, useEffect } from 'react';

import { TickerTape } from "react-ts-tradingview-widgets";

const CustomTickerTape = () => {
    const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
    const breakpoint = 780;

    const [tickerStyle, setTickerStyle] = useState (window.innerWidth <= breakpoint ? "compact" : "regular");
    const attributionStyle = {
        fontSize: "13px",
        lineHeight: "32px",
        position: "relative",
        textAlign: "center",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif",
        color: "rgb(157, 178, 189)",
        width: "100%",
    };
    const [divStyle, setDivStyle] = useState ({
        paddingTop: "0.25rem",
        height: window.innerWidth <= breakpoint ? "72px" : "44px",
        width: "100%",
        left: "0px",
        right: "0px",
        colorScheme: "null"
    });

    useEffect(() => {
        const handleResize = () => {
            setTickerStyle(window.innerWidth <= breakpoint ? "compact" : "regular");
            setDivStyle ({
                ...divStyle,
                height: window.innerWidth <= breakpoint ? "72px" : "44px"
            })
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [divStyle]);

    return (
        <React.Fragment>
            <div style={divStyle}>
                <TickerTape colorTheme={theme} isTransparent={true} displayMode={tickerStyle} symbols={[
                    {
                    "description": "Bitcoin",
                    "proName": "COINBASE:BTCUSD"
                    },
                    {
                    "description": "Ethereum",
                    "proName": "COINBASE:ETHUSD"
                    },
                    {
                    "description": "Monero",
                    "proName": "KRAKEN:XMRUSD"
                    },
                    {
                    "description": "Avalanche",
                    "proName": "COINBASE:AVAXUSD"
                    },
                    {
                    "description": "Polygon",
                    "proName": "COINBASE:MATICUSD"
                    },
                    {
                    "description": "Optimism",
                    "proName": "COINBASE:OPUSD"
                    },
                    {
                    "description": "BNB",
                    "proName": "BINANCE:BNBUSD"
                    },
                    {
                    "description": "Arbitrum",
                    "proName": "KRAKEN:ARBUSD"
                    },
                    {
                    "description": "Gnosis",
                    "proName": "KRAKEN:GNOUSD"
                    },
                    {
                    "description": "Fantom",
                    "proName": "GEMINI:FTMUSD"
                    },
                    {
                    "description": "Moonbeam",
                    "proName": "CRYPTO:GLMRUSD"
                    },
                    {
                    "description": "Boba Network",
                    "proName": "KRAKEN:BOBAUSD"
                    },
                    {
                    "description": "Aurora",
                    "proName": "COINBASE:AURORAUSD"
                    }
                ]} />
            </div>
            <div className="fade-in" style={attributionStyle}>

                    <p style={{color: "rgb(157, 178, 189)"}}>                <a href="https://www.tradingview.com/markets/" target="_blank" without rel="noreferrer" style={{textDecoration: "none", color: "rgb(41, 98, 255)"}}>Markets </a>By TradingView</p>
            </div>
        </React.Fragment>
    )
}

export default CustomTickerTape;
